'use strict';

const { bindGA } = require('./util/analytics-util');
const { getNestedValue } = require('lilly/util');
const { getProductData, queryAll, queryFirst } = require('lilly/domUtil');
const { LOYALTY_PROGRAM_ACTION, LOYALTY_PROGRAM_MODAL, LOYALTY_PROGRAM_LABEL, MODAL_CLOSE_LABEL, LOYALTY_MODAL_PAGE_SUBSECTION, LOYALTY_DASHBOARD_ACTION, OPEN_LABEL,
    LOYALTY_ACCOUNT_CARD, MY_ACCOUNT_NAME, ACCOUNT_PAGE_TYPE, VIEW_LOYALTY_DASHBOARD, MY_ACCOUNT_TAB, LOYALTY_PROGRAM_DETAILS, ORDER_CONFIRM_REGISTER_MODAL, LOYALTY_PROGRAM_WELCOME,
    LOYALTY_DRAWER_LINK, LOYALTY_OPT_IN_CHECKBOX, LOYALTY_OPT_IN_ACTION, LOYALTY_OPT_IN_CHECKBOX_SELECT_ACTION, LOYALTY_OPT_IN_CHECKBOX_DESELECT_ACTION, ORDER_CONFIRM_GUEST_ELEMENT,
    ACCOUNT_SIGN_IN_CATEGORY, CREATE_AN_ACCOUNT_ACTION, SUBMIT_LOYALTY_OPT_IN, SUBMIT_LOYALTY_NOT_OPT_IN, CREATE_ACCOUNT_ORDER_CONFIRM, LOYALTY_NEXT_BUTTON, ARROW_NEXT, CLOSE_ENROLL_NOW_MODAL,
    ENROLL_LOYALTY_CTA, LOYALTY_PROGRAM_ENROLLMENT, LOYALTY_PROGRAM_ENROLL_SUCCESS, GUEST_LOYALTY_ENROLL, ENROLL_LOYALTY, CLOSE_WELCOME_MODAL, LOYALTY_ENROLLMENT_EVENT_NAME, LOYALTY_PROGRAM_SUCCESS_ENROLL } = require('./util/constants');

/**
 * Analytics for Loyalty Dashboard Page
 */
function initEvents() {
    const headerLoyaltyProgram = queryFirst('.user-account-menu');
    const accountPageDashboard = queryFirst('.account-dashboard');
    const loyaltySlideOutContainer = queryFirst('.lilly-loyalty-container');
    const loyaltyProgramCheckbox = queryFirst('.loyalty-program-checkbox');
    const loyaltyProgramContextType = getNestedValue(window, 'utag_data.context_type') || '';
    const { utag_data: uData = {} } = window;
    /**
     * Getting the page type based on the context type
     * @returns {string} page type
     */
    function getLoyaltyProgramPageType() {
        if (loyaltyProgramContextType === 'login') {
            return ACCOUNT_PAGE_TYPE;
        }
        return getNestedValue(window, 'utag_data.page_type') || '';
    }

    // Trigger GA event on open of Lilly Loyalty Program Modal from header
    bindGA(headerLoyaltyProgram, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_DETAILS,
        event_label: OPEN_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION
    }, {
        bindOptions: {
            targetSelectors: [LOYALTY_DRAWER_LINK]
        },
        retriggerEvent: false
    });

    // Trigger GA event on click of close icon of lilly loyalty program modal
    bindGA(document, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_DETAILS,
        event_label: MODAL_CLOSE_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION
    }, {
        bindOptions: {
            targetSelectors: [LOYALTY_PROGRAM_MODAL]
        }
    });

    // Trigger GA event on click of create an account & enroll CTA of lilly loyalty program modal
    bindGA(loyaltySlideOutContainer, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_ACTION,
        event_label: ENROLL_LOYALTY,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION
    }, {
        bindOptions: {
            targetSelectors: [GUEST_LOYALTY_ENROLL]
        }
    });

    // Trigger GA event on click of Club Lilly nav item from dropdown in My Account Page
    bindGA(accountPageDashboard, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_DASHBOARD_ACTION,
        event_label: OPEN_LABEL,
        page_name: MY_ACCOUNT_NAME,
        page_type: ACCOUNT_PAGE_TYPE
    }, {
        bindOptions: {
            targetSelectors: [LOYALTY_ACCOUNT_CARD]
        }
    });
    // Trigger GA event on click of View Dashboard CTA on My Account Page
    bindGA(accountPageDashboard, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_DASHBOARD_ACTION,
        event_label: OPEN_LABEL,
        page_name: MY_ACCOUNT_TAB,
        page_type: ACCOUNT_PAGE_TYPE,

        //GA4 logged_in_engagement on click of View Dashboard on My Account Page
        event_name: 'logged_in_engagement',
        interaction_type: LOYALTY_PROGRAM_LABEL
    }, {
        bindOptions: {
            targetSelectors: [VIEW_LOYALTY_DASHBOARD]
        }
    });
    // Trigger GA event on click of Club Lilly CTA in loyalty opt in checkbox
    bindGA(loyaltyProgramCheckbox, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_DETAILS,
        event_label: OPEN_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION,
        page_type: getLoyaltyProgramPageType()
    }, {
        bindOptions: {
            targetSelectors: [LOYALTY_DRAWER_LINK]
        }
    });
    // Trigger GA event on click of loyalty opt in checkbox
    bindGA(document, (bindElement, srcElement) => {
        const orderConfirmationRegisteredEl = srcElement.closest(ORDER_CONFIRM_REGISTER_MODAL);
        const optInCheckboxAction = srcElement.checked ? LOYALTY_OPT_IN_CHECKBOX_SELECT_ACTION : LOYALTY_OPT_IN_CHECKBOX_DESELECT_ACTION;
        return {
            event_category: LOYALTY_PROGRAM_LABEL,
            event_action: LOYALTY_OPT_IN_ACTION,
            event_label: optInCheckboxAction,
            page_type: getLoyaltyProgramPageType(),
            page_subsection: orderConfirmationRegisteredEl ? LOYALTY_MODAL_PAGE_SUBSECTION : ''
        };
    }, {
        bindOptions: {
            targetSelectors: [LOYALTY_OPT_IN_CHECKBOX]
        }
    });
    // Trigger GA event on click of Create Account in Order Confirmation Page
    bindGA(document, (bindElement, srcElement) => {
        const orderConfirmGuestEl = srcElement.closest(ORDER_CONFIRM_GUEST_ELEMENT);
        const loyaltyOptInCheckboxEl = queryFirst(LOYALTY_OPT_IN_CHECKBOX, orderConfirmGuestEl);
        const optInCheckboxLabel = loyaltyOptInCheckboxEl.checked ? SUBMIT_LOYALTY_OPT_IN : SUBMIT_LOYALTY_NOT_OPT_IN;
        return {
            event_category: ACCOUNT_SIGN_IN_CATEGORY,
            event_action: CREATE_AN_ACCOUNT_ACTION,
            event_label: optInCheckboxLabel,
            page_type: getNestedValue(window, 'utag_data.page_type') || ''
        };
    }, {
        bindOptions: {
            targetSelectors: [CREATE_ACCOUNT_ORDER_CONFIRM]
        }
    });
    // Trigger GA event on enrolling in Loyalty Enroll Popup
    bindGA(document, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_ENROLLMENT,
        event_label: LOYALTY_PROGRAM_ENROLL_SUCCESS,
        page_type: LOYALTY_PROGRAM_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION,
        event_name: LOYALTY_ENROLLMENT_EVENT_NAME,
        interaction_type: LOYALTY_PROGRAM_SUCCESS_ENROLL
    }, {
        bindOptions: {
            targetSelectors: [ENROLL_LOYALTY_CTA]
        }
    });
    // Trigger GA event on close of enroll now popup
    bindGA(document, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_ACTION,
        event_label: MODAL_CLOSE_LABEL,
        page_type: LOYALTY_PROGRAM_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION
    }, {
        bindOptions: {
            targetSelectors: [CLOSE_ENROLL_NOW_MODAL]
        }
    });
    // Trigger GA event on click of Next Button in Loyalty Enroll Popup
    bindGA(document, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_WELCOME,
        event_label: ARROW_NEXT,
        page_type: LOYALTY_PROGRAM_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION
    }, {
        bindOptions: {
            targetSelectors: [LOYALTY_NEXT_BUTTON]
        }
    });
    // Trigger GA event on close of Next Button in Loyalty Enroll Popup
    bindGA(document, {
        event_category: LOYALTY_PROGRAM_LABEL,
        event_action: LOYALTY_PROGRAM_WELCOME,
        event_label: MODAL_CLOSE_LABEL,
        page_type: LOYALTY_PROGRAM_LABEL,
        page_subsection: LOYALTY_MODAL_PAGE_SUBSECTION
    }, {
        bindOptions: {
            targetSelectors: [CLOSE_WELCOME_MODAL]
        }
    });

    bindGA(queryAll('.loyalty-dashboard-unenroll'), (bindElement, srcElement) => {
        return {
            //GA360
            event_category: LOYALTY_PROGRAM_LABEL,
            event_action: LOYALTY_PROGRAM_ACTION,
            event_label: ENROLL_LOYALTY,

            //GA4
            page_name: uData.page_name,
            page_type: uData.page_type,
            loyalty_status: uData.loyalty_status,
            logged_in_status: uData.user_loggedin_status,
            event_name: LOYALTY_ENROLLMENT_EVENT_NAME,
            interaction_type: LOYALTY_PROGRAM_ACTION
        };
    }, {
        bindOptions: {
            targetSelectors: ['.loyalty-enrollnow-cta']
        }
    });
}
initEvents();
