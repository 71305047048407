'use strict';

const { bindGA } = require('./util/analytics-util');
const TULIP_CATEGORY = 'shop with stylist';

/**
 * Tulip Events
 */
function initTulipEvents() {
    const { body } = document;

    // Chat with a Stylist Link -- Footer, PDP, Store Details, Customer Service, and Shop with a Stylist
    bindGA(body, {
        event_category: TULIP_CATEGORY,
        event_action: 'initiate chat',
        event_label: 'chat link'
    }, {
        bindOptions: {
            targetSelectors: ['#stylist-chat-btn', '#stylist-chat', '.live-agent-online-button']
        }
    });

    // Book appointment link -- Customer Service, Store Details, and Shop with a Stylist
    bindGA(body, {
        event_category: TULIP_CATEGORY,
        event_action: 'initiate appointment',
        event_label: 'appointment link'
    }, {
        bindOptions: {
            targetSelectors: ['#appointment-chat-btn', '#appointment-chat']
        }
    });
}

initTulipEvents();
